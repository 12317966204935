<template>
  <vs-dialog scroll overflow-hidden not-close auto-width prevent-close v-model="this.active">
    <template #header>
      <h3>
        Notificaciones y Alertas
      </h3>
    </template>
    <vs-table>
      <template #thead>
        <vs-tr>
          <vs-th>
            Evento
          </vs-th>
          <vs-th center>
            <i class="fas fa-bell"></i>
            <!-- Notificaciones -->
          </vs-th>
          <vs-th center>
            <i class="fas fa-envelope"></i>
            <!-- Email -->
          </vs-th>
        </vs-tr>
      </template>
      <template #tbody>
        <vs-tr :key="i" v-for="(tr, i) in events" :data="tr">
          <vs-td>
            {{ tr }}
          </vs-td>
          <vs-td>
            <vs-switch success v-model="active3">
              <template #off>
                <i class="fas fa-volume-mute"></i>
              </template>
              <template #on>
                <i class="fas fa-bell"></i>
              </template>
            </vs-switch>
          </vs-td>
          <vs-td>
            <vs-switch success v-model="active3">
              <template #off>
                <i class="fas fa-bell-slash"></i>
              </template>
              <template #on>
                <i class="fas fa-envelope"></i>
              </template>
            </vs-switch>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <template #footer>
      <div class="con-footer">
        <vs-button block success transparent to="/">
          Ok
        </vs-button>
      </div>
    </template>
  </vs-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Settings",
  methods: {},
  watch: {},
  data: () => {
    return {
      active: true,
      active3: "cambiarEsto",
      events: ["Nuevo follower", "Nuevo participante", "Nuevo contenido", "Mensajes", "Agenda"]
    };
  }
};
</script>

<style scoped></style>
